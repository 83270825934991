import { apiClient} from "./BaseService.js";


export default {
    callRoleReadAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("roleRead", payload);
    },
    callRoleCreateAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("roleCreate", payload);
    },
    callUserRolePrivilegeCreateAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("userRolePrivilegeCreate", payload);
    },
    callRoleReadSingleAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("roleReadSingle", payload);
    },
};