import { apiClient} from "./BaseService.js";


export default {
    callCustomerTransactionsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("customerTransactions", payload);
    },
    callTransactionEnquiryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("transactionEnquiry", payload);
    },
    callTellerTransactionsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("tellerTransactions", payload);
    },
    callReverseTransactionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("reverseTransaction", payload);
    },

};