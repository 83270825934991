<template>
  <card class="card-stats dash-card" :show-footer-line="true">
    <div class="row">

      <div class="col">
        <slot>
          <h5 class="card-title text-capitalize mb-2" v-if="title">{{title}}</h5>
          <span class="mb-0 subtitle" v-if="subTitle"><b>{{subTitle}}</b></span>
        </slot>
      </div>

      <div class="col-auto" v-if="$slots.icon || icon">
        <slot name="icon">
          <div class="icon icon-shape rounded-circle shadow"
               :class="[`bg-${type}`, iconClasses]">
            <i :class="icon"></i>
          </div>
        </slot>
      </div>
    </div>

    <p class="mt-0 mb-0 text-sm">
      <slot name="footer">

      </slot>
    </p>
  </card>
</template>
<script>
  import Card from './Card.vue';

  export default {
    name: 'stats-card',
    components: {
      Card
    },
    props: {
      type: {
        type: String,
        default: 'primary'
      },
      icon: String,
      title: String,
      subTitle: String,
      iconClasses: [String, Array]
    }
  };
</script>
<style>
.card-title{
  font-weight: 900;
  font-size:18px;
  line-height:24px;
  color: #175D85;
}
.dash-card{
  border:solid #9fc2d6 !important;
  border-radius: 30px !important;

}

.subtitle{
  font-weight: 500;
  font-size:20px;
  line-height: 28px;
  color: #BFA161;
}

.icon-shape{
  background-color: #175D85;
}
</style>
