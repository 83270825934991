import { apiClient } from "./BaseService.js";


export default {
    callglLevel1CreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("glLevel1Create", payload);
    },

    callglLevel1ReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("glLevel1Read", payload);
    },
    callglLevel1CheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("glLevel1Checker", payload);
    },

    callglLevel2CreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("glLevel2Create", payload);
    },

    callglLevel2ReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("glLevel2Read", payload);
    },
    callglLevel2CheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("glLevel2Checker", payload);
    },


};