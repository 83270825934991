import { apiClient} from "./BaseService.js";


export default {
    callLoanCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("loanCreate", payload);
    },
    callLoanReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("loanRead", payload);
    },
    callLoanAmortizationApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("loanAmortization", payload);
    },
    callLoanUpdateStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("loanUpdateStatus", payload);
    },

};