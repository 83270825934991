import { apiClient} from "./BaseService.js";


export default {
    callBalanceEnquiryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("balanceEnquiry", payload);
    },
    callBankListApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("bankList", payload);
    },
    callReadProductApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readProduct", payload);
    },
    callFundTransferApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("fundTransfer", payload);
    },
    callNameEnquiryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("nameEnquiry", payload);
    },
    callFundTransferCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("fundTransferChecker", payload);
    },
    callCashTransactionApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("cashTransaction", payload);
    },
    callCashWithdrawalCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("cashWithdrawalChecker", payload);
    },
    callCashDepositCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("cashDepositChecker", payload);
    },
    callCashTransactionCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("cashTransactionChecker", payload);
    },

};