<template>
  <div class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs gold-gradient brown-gradient"
       @mouseenter="$sidebar.onMouseEnter()"
       @mouseleave="$sidebar.onMouseLeave()">
    <div class="scrollbar-inner" ref="sidebarScrollArea">
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand" href="https://coregemsystems.com/#/">
          <img src="@/assets/coregemlogotext.svg" class="navbar-brand-img" alt="Sidebar logo">
        </a>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidenav-toggler d-none d-xl-block"
               :class="{'active': !$sidebar.isMinimized }"
               @click="minimizeSidebar">
            <div class="sidenav-toggler-inner">
             <i class="fas fa-bars" style="color:white;"></i>
            </div>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner">
        <ul class="navbar-nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </ul>
        <slot name="links-after"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: 'sidebar',
  data() {
      return {
        logos:''
      }},
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: 'https://coregem-imgs.s3.amazonaws.com/logo_header_removebg.png',
      description: 'Sidebar app logo'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  computed:{
           ...mapState(['auth']),
    ...mapActions(['logOut']),
    ...mapGetters({
      bankLogo: getters => getters.auth.getUserBankInfo
    }),
    
    },
  mounted() {
    //console.log(this.props);//.logo.default = "https://coregem-imgs.s3.amazonaws.com/logo_header_removebg.png";
    this.logos = "https://coregem-imgs.s3.amazonaws.com/logo_header_removebg.png";
    console.log(this.$store.getters.getUserBankInfo);
    //console.log(this.bankLogos);
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth
    this.minimizeSidebar()

  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style lang="css" scoped>
.brown-gradient{
        background-color:#4C3847;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
  background-color:none;
}

</style>
