<template>
  <footer class="footer px-4 mx-2">
      <div class="col-lg-6">
        <div class="copyright">
          © {{year}} <a href="https://coregemsystems.com" class="font-weight-bold ml-1" target="_blank"><img src="@/assets/coregemlogo.jpeg" class="footer-img"/></a> <!-- &
          <a href="https://www.binarcode.com?ref=creative-tim" class="font-weight-bold ml-1" target="_blank">Binar Code</a>-->
        </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>

.footer {
  display: flex;
  justify-content: center;
  background:none !important;
}

.copyright {
font-family: Roboto;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
text-align: center;
/* identical to box height */


color: #666666;
}
.footer-img{
  width: 20% !important;
}
</style>
