import { apiClient} from "./BaseService.js";

export default {
    callTellerCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("tellerCreate", payload);
    },
    callTellerReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("tellerRead", payload);
    },
    callTellerCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("tellerChecker", payload);
    },

};