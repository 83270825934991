var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper text-white"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-white"},[_vm._v("Management")]),_c('sidebar-item',{attrs:{"link":{ name: 'User',
                        icon: 'fas fa-user',
                        path: '/user/all' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Role',
                        icon: 'fas fa-project-diagram',
                        path: '/role/all' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Branch',
                        icon: 'fas fa-code-branch',
                        path: '/branch/all'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Account',
                        icon: 'fas fa-wallet',
                        path: '/account/accounts'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Customer',
                        icon: 'fas fa-users',
                        path: '/customer/all'}}}),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-white"},[_vm._v("Products")]),_c('sidebar-item',{attrs:{"link":{ name: 'Product',
                        icon: 'fas fa-hands',
                        path: '/product/all' }}}),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-white"},[_vm._v("Settings")]),_c('sidebar-item',{attrs:{"link":{ name: 'Settings',
                        icon: 'fas fa-cog',
                        path: '/settings/all' }}}),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-white"},[_vm._v("System Operations")]),_c('sidebar-item',{attrs:{"link":{
                        path:'/report/all',
                        name: 'Reports',
                        icon: 'ni ni-support-16 text-primary'
                      }}}),_c('sidebar-item',{attrs:{"link":{
                        path:'/loan/all',
                        name: 'Loan Processing',
                        icon: 'ni ni-support-16 text-primary'
                      }}}),_c('sidebar-item',{attrs:{"link":{ name: 'EOD Run',icon: 'ni ni-support-16 text-primary', path: '/report/eod/all' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'EOM Run', icon: 'ni ni-support-16 text-primary',path: '/report/eom/all' }}}),_c('sidebar-item',{attrs:{"link":{
                        name: 'Product',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Product', path: '/product/select-product-type' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Products', path: '/product/select-product-type-view' }}})],1),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-white"},[_vm._v("Transaction")]),_c('sidebar-item',{attrs:{"link":{
                        name: 'Switch Transaction',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'NIP Inwards', path: '/switch-transactions/all-nip-inward' }}}),_c('sidebar-item',{attrs:{"link":{
                       name: 'Transaction',
                       icon: 'ni ni-single-02 text-primary'
                     }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Customer Enquiry',path:'/funds-transfer/customer-balance-enquiry'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Customer Transactions',path:'/transaction/customer-transactions'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Transaction Enquiry',path:'/transaction/transaction-enquiry'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Fund Transfer',path:'/funds-transfer/transfer-funds'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Fund Transfer Checkers Queue',path:'/funds-transfer/ft-checkers-queue'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'GL Fund Transfer',path:'/funds-transfer/cash-transaction'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cash Transactions',path:'/funds-transfer/cash-withdrawals'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cash Withdrawal Checkers Queue',path:'/funds-transfer/cw-checkers-queue'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cash Deposit Checker Queue',path:'/funds-transfer/cd-checkers-queue'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cash Transaction Checker Queue',path:'/funds-transfer/ct-checkers-queue'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Transaction History',path:'/transaction/teller-transaction-history'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Manual Reversal',path:'/transaction/manual-reversal'}}})],1),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-white"},[_vm._v("Queues & Alerts")]),_c('sidebar-item',{attrs:{"link":{ name: 'Authorize Checkers Queue',
          icon: 'ni ni-support-16 text-primary',
           path: '/checkers-alert/authorize-checker-queue' }}}),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-white"})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }