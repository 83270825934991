import { apiClient} from "./BaseService.js";


export default {
    callTillCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("tillCreate", payload);
    },
    callTillReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("tillRead", payload);
    },
    callTillCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("tillChecker", payload);
    },

};