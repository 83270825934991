import axios from "axios";
import { qgBaseUrl, imageUpload } from "../app.config";
import store from "../store/store";
import router from "../routes/router";
import Swal from 'sweetalert2'


// import store from "@/store/store";

export const apiClient = axios.create({
  baseURL: qgBaseUrl,
  withCredentials: false,
  headers: {
    //Accept: "application/json",
    //"Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  }
});

export const apiClient2 = axios.create({
  baseURL: qgBaseUrl,
  withCredentials: false,
  headers: {
    //Accept: "application/json",
    "Content-Type": "application/json",
    //"Access-Control-Allow-Origin": "*",
  }
});


export const apiClient3 = axios.create({
  baseURL: imageUpload,
  withCredentials: false,
  headers: {
    //Accept: "application/json",
    "Content-Type": "application/json",
    //"Access-Control-Allow-Origin": "*",
  }
});

// https://cors-anywhere.herokuapp.com


apiClient.interceptors.request.use((config) => {
  // console.log("Axios Interceptor Config ==>", config);
  const token = store.getters.getToken;
  const tok = store.getters.getUserToken; //["auth/getUserToken"];
  config.headers.Authorization = (token == null) ? tok : token;
  config.headers.Authorization = token;
  return config
})

apiClient3.interceptors.request.use(config => {
  return config
})

apiClient.interceptors.response.use(response => {
  if (response != null) {
    if (response.data != null) {
      if (response.data.responseCode === '115') {
        localStorage.clear();
        let timerInterval
        Swal.fire({
          title: 'Session timed out',
          html: 'Please re-authenticate',
          icon:"info",
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
            location.reload()
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
              location.reload()
          }
        })
      }
    }
  }

  return response
});
