//import { mapState, mapActions } from 'vuex';
import transactionService from "../../service/transactionService";
import swal from 'sweetalert2'
import router from "../../routes/router";
export const state = {
    token: null,
    transaction_loading: false,
    transaction_response: {},
    userInfo: {},
    transaction_errors: null,
    transaction_success: null,

}
export const getters = {
    getTransactionResponse: state => {
        // console.log("getter getTok")
        return state.transaction_response
    },


}
export const mutations = {
    updateTransactionResponse: (state, payload) => {
        state.transaction_response = payload
    },
    updateTransactionLoading: (state, payload) => {
        state.transaction_loading = payload
    },
    updateTransactionError: (state, payload) => {
        state.transaction_errors = payload
    },
    updateTransactionSuccess: (state, payload) => {
        state.transaction_success = payload
    },

}
export const actions = {
    customerTransactions: ({ commit, dispatch, rootState }, payload) => {
        commit("updateTransactionLoading", true)
        commit("updateTransactionResponse", {});
        commit("updateTransactionError", null);
        commit("updateTransactionSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return transactionService.callCustomerTransactionsApi(payload)
            .then(response => {
                let responseData = response.data;

                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    commit("updateTransactionResponse", responseData);
                    commit("updateTransactionSuccess", responseData.responseMessage);
                    commit("updateTransactionLoading", false);
                } else {
                    commit("updateTransactionLoading", false);
                    commit("updateTransactionError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateTransactionLoading", false);
                commit("updateTransactionError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    transactionEnquiry: ({ commit, dispatch, rootState }, payload) => {
        commit("updateTransactionLoading", true)
        commit("updateTransactionResponse", {});
        commit("updateTransactionError", null);
        commit("updateTransactionSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return transactionService.callTransactionEnquiryApi(payload)
            .then(response => {
                let responseData = response.data;

                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    commit("updateTransactionResponse", responseData);
                    commit("updateTransactionSuccess", responseData.responseMessage);
                    commit("updateTransactionLoading", false);
                } else {
                    commit("updateTransactionLoading", false);
                    commit("updateTransactionError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateTransactionLoading", false);
                commit("updateTransactionError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    tellerTransactions: ({ commit, dispatch, rootState }, payload) => {
        commit("updateTransactionLoading", true)
        commit("updateTransactionResponse", {});
        commit("updateTransactionError", null);
        commit("updateTransactionSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return transactionService.callTellerTransactionsApi(payload)
            .then(response => {
                let responseData = response.data;

                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    commit("updateTransactionResponse", responseData);
                    commit("updateTransactionSuccess", responseData.responseMessage);
                    commit("updateTransactionLoading", false);
                } else {
                    commit("updateTransactionLoading", false);
                    commit("updateTransactionError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateTransactionLoading", false);
                commit("updateTransactionError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    reverseTransaction: ({ commit, dispatch, rootState }, payload) => {
        commit("updateTransactionLoading", true)
        commit("updateTransactionResponse", {});
        commit("updateTransactionError", null);
        commit("updateTransactionSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return transactionService.callReverseTransactionApi(payload)
            .then(response => {
                let responseData = response.data;

                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    commit("updateTransactionResponse", responseData);
                    commit("updateTransactionSuccess", responseData.responseMessage);
                    commit("updateTransactionLoading", false);
                } else {
                    commit("updateTransactionLoading", false);
                    commit("updateTransactionError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateTransactionLoading", false);
                commit("updateTransactionError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};