import { apiClient} from "./BaseService.js";


export default {
    callUserReadAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("userRead", payload);
    },
    callUserCreateAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("userCreate", payload);
    },
    callUserUpdateAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("userUpdate", payload);
    },
    callUserResetAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("resetUser", payload);
    },
    callUserCheckerAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("userChecker", payload);
    },
    callUserReadSingleAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("userReadSingle", payload);
    },
    callUserReadSingleByIdAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("userReadSingleById", payload);
    },

};