<template>
  <div class="wrapper text-white">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <!--<sidebar-item
          :link="{
            name: 'Dashboards',
            icon: 'ni ni-shop text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'Dashboard', path: '/dashboard' }"
          ></sidebar-item>

        </sidebar-item>-->
  <!-----------User Management------------------->
<h6 class="navbar-heading pt-3 pb-1 text-white">Management</h6>
 <sidebar-item :link="{ name: 'User',
                        icon: 'fas fa-user',
                        path: '/user/all' }"/>
          <sidebar-item :link="{ name: 'Role',
                        icon: 'fas fa-project-diagram',
                        path: '/role/all' }"/>
          <sidebar-item :link="{ name: 'Branch',
                        icon: 'fas fa-code-branch',
                        path: '/branch/all'}"/>
          <sidebar-item :link="{ name: 'Account',
                        icon: 'fas fa-wallet',
                        path: '/account/accounts'}"/>
          <sidebar-item :link="{ name: 'Customer',
                        icon: 'fas fa-users',
                        path: '/customer/all'}"/>



        <!----------------------End of User Management------------------>
 <!-----------Product------------------->
<h6 class="navbar-heading pt-3 pb-1 text-white">Products</h6>
 <sidebar-item :link="{ name: 'Product',
                        icon: 'fas fa-hands',
                        path: '/product/all' }"/>
  


        <!----------------------End of Product------------------>

  <!-----------Setting------------------->
<h6 class="navbar-heading pt-3 pb-1 text-white">Settings</h6>
 <sidebar-item :link="{ name: 'Settings',
                        icon: 'fas fa-cog',
                        path: '/settings/all' }"/>

        <!----------------------End of Setting------------------>


<!----------------------Maintenance------------------------>
<!--<h6 class="navbar-heading pt-3 pb-1 text-white">Maintenance</h6>-->
<!--        <sidebar-item :link="{-->
<!--                        name: 'Internal Accounts',-->
<!--                        icon: 'fas fa-user-plus'-->
<!--                      }">-->
<!--          <sidebar-item :link="{ name: 'Create Internal Account', path: '/internal-account/create' }"/>-->
<!--          <sidebar-item :link="{ name: 'All Internal Account', path: '/internal-account/all' }"/>-->
<!--          &lt;!&ndash;<sidebar-item :link="{ name: 'Internal Account Checkers Queue', path: '/internal-account/checkers-queue' }"/>&ndash;&gt;-->
<!--        </sidebar-item>-->

<!--        <sidebar-item :link="{-->
<!--                        name: 'Bank',-->
<!--                        icon: 'fas fa-university'-->
<!--                      }">-->
<!--          <sidebar-item :link="{ name: 'Upload Logo',path:'/bank/upload-logo'}"/>-->

<!--        </sidebar-item>-->

<!--        <sidebar-item :link="{-->
<!--                        name: 'Branch',-->
<!--                        icon: 'fas fa-code-branch'-->
<!--                      }">-->
<!--          <sidebar-item :link="{ name: 'Create Branch',path:'/branch/create'}"/>-->
<!--          <sidebar-item :link="{ name: 'All Branch', path: '/branch/all' }"/>-->
<!--          &lt;!&ndash;<sidebar-item :link="{ name: 'Branch Checkers Queue', path: '/branch/checkers-queue' }"/>&ndash;&gt;-->
<!--        </sidebar-item>-->

<!--        <sidebar-item :link="{-->
<!--                        name: 'Customer',-->
<!--                        icon: 'fas fa-users'-->
<!--                      }">-->
<!--          <sidebar-item :link="{ name: 'Create Account',path:'/customer/create-account'}"/>-->
<!--          <sidebar-item :link="{ name: 'Create Customer',path:'/customer/create-customer'}"/>-->
<!--          <sidebar-item :link="{ name: 'View Customer', path: '/customer/view' }"/>-->
<!--          <sidebar-item :link="{ name: 'View Customer Accounts', path: '/customer/view-accounts' }"/>-->
<!--          &lt;!&ndash;<sidebar-item :link="{ name: 'Customer Checkers Queue', path: '/customer/checkers-queue' }"/>-->
<!--          <sidebar-item :link="{ name: 'Customer Sub Account Checkers Queue', path: '/customer/sub-account-checkers-queue' }"/>-&ndash;&gt;-->
<!--          <sidebar-item :link="{ name: 'Create Customer Upload',path:'/customer/create-upload'}"/>-->
<!--          <sidebar-item :link="{ name: 'Request Account Statement',path:'/customer/account-statement'}"/>-->
<!--        </sidebar-item>-->
<!----------------------End of Maintenance----------------->
<!-----------Operations------------------->
<h6 class="navbar-heading pt-3 pb-1 text-white">System Operations</h6>
<sidebar-item :link="{
                        path:'/report/all',
                        name: 'Reports',
                        icon: 'ni ni-support-16 text-primary'
                      }"> </sidebar-item>
<!--          <sidebar-item :link="{ name: 'Create Report', path: '/report/create' }"/>-->
<!--          &lt;!&ndash;<sidebar-item :link="{ name: 'View Batch', path: '/report/add-entry-to-batch' }"/>&ndash;&gt;-->
<!--          <sidebar-item :link="{ name: 'View Report', path: '/report/all' }"/>-->
<!--         &lt;!&ndash;<sidebar-item :link="{ name: 'Report Checkers Queue', path: '/report/checkers-queue' }"/>&ndash;&gt;-->
<!--        </sidebar-item>-->

        <sidebar-item :link="{
                        path:'/loan/all',
                        name: 'Loan Processing',
                        icon: 'ni ni-support-16 text-primary'
                      }">
<!--          <sidebar-item :link="{ name: 'Book Loan', path: '/loan/book-loan' }"/>-->
          <!--<sidebar-item :link="{ name: 'View Batch', path: '/report/add-entry-to-batch' }"/>-->
<!--          <sidebar-item :link="{ name: 'View Loans', path: '/loan/all' }"/>-->
         <!---<sidebar-item :link="{ name: 'Report Checkers Queue', path: '/report/checkers-queue' }"/>-->
        </sidebar-item>

<!--        <sidebar-item :link="{-->
<!--                        name: 'System Maintenance',-->
<!--                        icon: 'ni ni-support-16 text-primary'-->
<!--                      }">  </sidebar-item> -->  

          <sidebar-item :link="{ name: 'EOD Run',icon: 'ni ni-support-16 text-primary', path: '/report/eod/all' }"/>
          <!--<sidebar-item :link="{ name: 'View Batch', path: '/report/add-entry-to-batch' }"/>-->
          <sidebar-item :link="{ name: 'EOM Run', icon: 'ni ni-support-16 text-primary',path: '/report/eom/all' }"/>
         <!-- <sidebar-item :link="{ name: 'Journal Checkers Queue', path: '/journal/checkers-queue' }"/>-->


       
        <!----------------------End of Operations------------------>
<!----------------------Products------------------------>
<!--<h6 class="navbar-heading pt-3 pb-1 text-white">Products</h6>-->
       <sidebar-item :link="{
                        name: 'Product',
                        icon: 'ni ni-support-16 text-primary'
                      }">
          <sidebar-item :link="{ name: 'Create Product', path: '/product/select-product-type' }"/>
          <sidebar-item :link="{ name: 'View Products', path: '/product/select-product-type-view' }"/>

        </sidebar-item>


<!----------------------End of Products----------------->

<!----------------------Cash and Teller------------------------>
<h6 class="navbar-heading pt-3 pb-1 text-white">Transaction</h6>
        <sidebar-item :link="{
                        name: 'Switch Transaction',
                        icon: 'ni ni-support-16 text-primary'
                      }">
          <sidebar-item :link="{ name: 'NIP Inwards', path: '/switch-transactions/all-nip-inward' }" />

       <sidebar-item :link="{
                       name: 'Transaction',
                       icon: 'ni ni-single-02 text-primary'
                     }">   </sidebar-item>
          <sidebar-item :link="{ name: 'Customer Enquiry',path:'/funds-transfer/customer-balance-enquiry'}"/>
         <sidebar-item :link="{ name: 'Customer Transactions',path:'/transaction/customer-transactions'}"/>
         <sidebar-item :link="{ name: 'Transaction Enquiry',path:'/transaction/transaction-enquiry'}"/>
         <sidebar-item :link="{ name: 'Fund Transfer',path:'/funds-transfer/transfer-funds'}"/>
          <sidebar-item :link="{ name: 'Fund Transfer Checkers Queue',path:'/funds-transfer/ft-checkers-queue'}"/>
         <sidebar-item :link="{ name: 'GL Fund Transfer',path:'/funds-transfer/cash-transaction'}"/>
         <sidebar-item :link="{ name: 'Cash Transactions',path:'/funds-transfer/cash-withdrawals'}"/>
          <sidebar-item :link="{ name: 'Cash Withdrawal Checkers Queue',path:'/funds-transfer/cw-checkers-queue'}"/>
          <sidebar-item :link="{ name: 'Cash Deposit Checker Queue',path:'/funds-transfer/cd-checkers-queue'}"/>
          <sidebar-item :link="{ name: 'Cash Transaction Checker Queue',path:'/funds-transfer/ct-checkers-queue'}"/>
         <sidebar-item :link="{ name: 'Transaction History',path:'/transaction/teller-transaction-history'}"/>
         <sidebar-item :link="{ name: 'Manual Reversal',path:'/transaction/manual-reversal'}"/>

    </sidebar-item>

       

       <!-- <sidebar-item :link="{
                       name: 'Teller',
                       icon: 'ni ni-single-02 text-primary'
                     }">
         <sidebar-item :link="{ name: 'Create Teller',path:'/teller/create'}"/>
         <sidebar-item :link="{ name: 'List Tellers', path: '/teller/all' }"/>
          <sidebar-item :link="{ name: 'Teller Checkers Queue', path: '/teller/checkers-queue' }"/>
       </sidebar-item> -->

        <!-- <sidebar-item :link="{
                       name: 'Till',
                       icon: 'ni ni-single-02 text-primary'
                     }">
         <sidebar-item :link="{ name: 'Create Till',path:'/till/create'}"/>
         <sidebar-item :link="{ name: 'View Till', path: '/till/all' }"/>
          <sidebar-item :link="{ name: 'Till Checkers Queue', path: '/till/checkers-queue' }"/>
       </sidebar-item> -->


       <!-- <sidebar-item :link="{
                       name: 'Vault',
                    icon: 'ni ni-single-02 text-primary'
                    }">
        <sidebar-item :link="{ name: 'Create Vault',path:'/vault/create'}"/>
        <sidebar-item :link="{ name: 'View Vault', path: '/vault/all' }"/>
          <sidebar-item :link="{ name: 'Vault Checker Queue', path: '/vault/checkers-queue' }"/>
       </sidebar-item>  -->
<!----------------------End of Cash and Teller----------------->

<!-----------Chart of Account------------------->
<!--<h6 class="navbar-heading pt-3 pb-1 text-white">Charts of Accounts</h6>-->
<!--        <sidebar-item :link="{-->
<!--                        name: 'Chart of Account',-->
<!--                        icon: 'ni ni-support-16 text-primary'-->
<!--                      }">-->
          <!--<sidebar-item :link="{ name: 'Create Level 1 GL', path: '/glLevel1/create' }"/>-->
<!--          <sidebar-item :link="{ name: 'Level 1 GL', path: '/glLevel1/view' }"/>-->
          <!--<sidebar-item :link="{ name: 'Level 1 GL Checkers Queue', path: '/glLevel1/checkers-queue' }"/>--->
          <!-- <sidebar-item :link="{ name: 'Create Level 2 GL', path: '/glLevel2/create' }"/>-->
<!--          <sidebar-item :link="{ name: 'Level 2 GL', path: '/glLevel2/view' }"/>-->
          <!--<sidebar-item :link="{ name: 'Level 2 GL Checkers Queue', path: '/glLevel2/checkers-queue'}"/>--->


<!--        </sidebar-item>-->

        <!----------------------End of Chart of Account------------------>

        <!-----------Journal------------------->
<!--<h6 class="navbar-heading pt-3 pb-1 text-white">Multiple Transactions</h6>-->
<!--        <sidebar-item :link="{-->
<!--                        name: 'Journal',-->
<!--                        icon: 'ni ni-support-16 text-primary'-->
<!--                      }">-->
<!--          <sidebar-item :link="{ name: 'Open Batch', path: '/journal/open-batch' }"/>-->
          <!--<sidebar-item :link="{ name: 'View Batch', path: '/journal/add-entry-to-batch' }"/>-->
<!--          <sidebar-item :link="{ name: 'View Batches', path: '/journal/view' }"/>-->
          <!--<sidebar-item :link="{ name: 'Journal Checkers Queue', path: '/journal/checkers-queue' }"/>--->


<!--        </sidebar-item>-->

        <!----------------------End of Journal------------------>

<!------------------------------Checker and Alert------------------>
<h6 class="navbar-heading pt-3 pb-1 text-white">Queues &#38; Alerts</h6>
        <!--<sidebar-item :link="{
                        name: 'Switch Transaction',
                        icon: 'ni ni-support-16 text-primary'
                      }">-->
          <sidebar-item :link="{ name: 'Authorize Checkers Queue',
          icon: 'ni ni-support-16 text-primary',
           path: '/checkers-alert/authorize-checker-queue' }"/>
          <!--</sidebar-item>-->
<!----------------------------------End of Checker and Alert----------->
<h6 class="navbar-heading pt-3 pb-1 text-white"></h6>
      </template>

    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(className);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    name:"DashboardLayout",
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      call(){
        console.log("heollllldfdfdfd");
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
.text-white{
  color: white;
}
sidebar-item{
  color: white;
}
::webkit-scrollbar{
  width: 10px !important;
}

::webkit-scrollbar-track{
  background:#f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff !important;
}


.fas{ color: #BFA161;}
</style>
