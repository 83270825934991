import { apiClient} from "./BaseService.js";


export default {
    callProductReadAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readProduct", payload);
    },
    callGLLevel2ReadAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("glLevel2Read", payload);
    },
    callReadProductAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readProduct", payload);
    },
    callReadProductTemplateAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readProductTemplate", payload);
    },
    callReadProductFormAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readProductForm", payload);
    },
    callCreateProductAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("createProduct", payload);
    },
    callUpdateProductAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("updateProduct", payload);
    },
    callCreateAccountingEntryAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("createAccountingEntry", payload);
    },
    callReadAccountingEntryAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readAccountingEntry", payload);
    },
    callReadSlabsAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readSlabs", payload);
    },
    callReadSingleSlabAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readSingleSlab", payload);
    },
    callReadSingleAccountingEntryAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readSingleAccountingEntry", payload);
    },
    callSubmitEntriesAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("submitEntries", payload);
    },


};