import {apiClient, apiClient2} from './BaseService.js'


export default {
  callLogonApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return apiClient2.post("logon", payload);
  },
  callChangeDefaultPasswordApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return apiClient2.post("changeDefaultPassword", payload);
  },
  callLogOutApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return apiClient2.post("logOut", payload);
  },
  callReadBankReadSingleApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return apiClient.post("bankReadSingle", payload);
  },

};
