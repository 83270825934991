import { apiClient} from "./BaseService.js";


export default {
    callEodReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("eodRead", payload);
    },
    callEodReadSingleApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("eodReadSingle", payload);
    },
    callEodCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("eodCreate", payload);
    },

    callEomReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("eomRead", payload);
    },
    callEomReadSingleApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("eomReadSingle", payload);
    },
    callEomCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("eomCreate", payload);
    },
    callReportCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("reportChecker", payload);
    },
    callReportReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("reportRead", payload);
    },
    callReportReadColumnApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("reportReadColumn", payload);
    },
    callReportReadSingleApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("reportReadSingle", payload);
    },
    callReadTablesApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readTables", payload);
    },
    callReadDepartmentsApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readDepartments", payload);
    },
    callReportCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("reportCreate", payload);
    },






};