import { apiClient } from "./BaseService.js";


export default {
    callBatchCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("batchCreate", payload);
    },
    callSubmitBatchEntryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("submitBatchEntry", payload);
    },
    callSubmitBulkBatchEntryApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("submitBulkBatchEntry", payload);
    },
    callBatchCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("batchChecker", payload);
    },
    callBatchCloseApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("batchClose", payload);
    },
    callBatchReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("batchRead", payload);
    },
    callUpdateBatchStatusApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("updateBatchStatus", payload);
    },
};