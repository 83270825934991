//import { mapState, mapActions } from 'vuex';
import ProductService from "../../service/ProductService";
import RoleService from "../../service/RoleService";
import role from "./role";
import swal from 'sweetalert2'
import router from "../../routes/router";

export const state = {
    token: null,
    product_loading: false,
    product_loading1: false,
    product_response: {},
    product_response_edit: {},
    product_view: {},
    product_view_CH: {},
    product_view_AC: {},
    product_view_LO: {},
    product_view_IN: {},
    product_view_FT: {},
    product_view_AE: {},
    product_res: {},
    singleSlab: {
        slabCode: 'SlabOne',
        slabMaxAmount: '99999999999999999.00'
    },
    selectAcc: {},
    page: 'add',
    assignResponse: {},
    gl_response: {},
    response_product_selected: null,
    product_errors: null,
    product_success: null,
    get_allproductdata: {
        code: '',
        description: ''
    },
    all_product_payload:{},

}
export const getters = {
    getBProductResponse: state => {
        // console.log("getter getTok")
        return state.product_response
    },

}
export const mutations = {
    updateProductResponse: (state, payload) => {
        state.product_response = payload
    },
    updateProductResponseEdit: (state, payload) => {
        state.product_response_edit = payload
    },

    updateProductView: (state, payload) => {
        state.product_view = payload
    },
    updateProductViewCharge: (state, payload) => {
        state.product_view_CH = payload
    },
    updateProductViewInterest: (state, payload) => {
        state.product_view_IN = payload
    },
    updateProductViewLoan: (state, payload) => {
        state.product_view_LO = payload
    },
    updateProductViewFundTransfer: (state, payload) => {
        state.product_view_FT = payload
    },
    updateProductViewAccount: (state, payload) => {
        state.product_view_AC = payload
    },
    updateProductViewAccountEntry: (state, payload) => {
        state.product_view_AE = payload
    },






    UpdateProductSelect: (state, payload) => {
        state.response_product_selected = payload
    },
    updateGLResponse: (state, payload) => {
        state.gl_response = payload
    },
    updateProductRole: (state, payload) => {
        state.product_role = payload
    },
    updateProductLoading: (state, payload) => {
        state.product_loading = payload
    },
    updateProductLoading1: (state, payload) => {
        state.product_loading1 = payload
    },
    updateProductError: (state, payload) => {
        state.product_errors = payload
    },
    updateProductSuccess: (state, payload) => {
        state.product_success = payload
    },
    updateSingleSlab: (state, payload) => {
        state.singleSlab = payload
    },
    updateAssignResponse: (state, payload) => {
        state.assignResponse = payload
    },
    updateSelectAcc: (state, payload) => {
        state.selectAcc = payload
    },
    updatePage: (state, payload) => {
        state.page = payload
    },

    updateProductdata: (state, payload) => {
        state.get_allproductdata = payload
    },

    updateProductPayloadFT: (state, payload) => {
        Object.assign(state.all_product_payload, { FT: payload})
            //state.all_product_payload = payload
    },

    updateProductPayloadCH: (state, payload) => {
        Object.assign(state.all_product_payload, { CH: payload});
                //state.all_productdata = payload
    },
  updateProductPayloadAE: (state, payload) => {
    Object.assign(state.all_product_payload, { AE: payload});
    //state.all_productdata = payload
  },
  updateProductPayloadSlab: (state, payload) => {
    Object.assign(state.all_product_payload, { Slab: payload});
  },

}
export const actions = {
    submitEntries: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callSubmitEntriesAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateProductSuccess", responseData.responseMessage);
                    commit("updateProductLoading", false);
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    readSingleAccountingEntry: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        // commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadSingleAccountingEntryAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updatePage", 'edit');
                    router.push({ name: 'AssignEntries' });
                    //commit("updateProductSuccess", responseData.responseMessage);
                    commit("updateAssignResponse", responseData);
                    //commit("updateProductSuccess", responseData.responseMessage);
                    commit("updateProductLoading", false);
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },

    readSingleSlab: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        commit("updateSingleSlab", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadSingleSlabAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateSingleSlab", responseData);
                    commit("updateProductLoading", false);
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },

    addSlab: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        commit("updatePage", payload.action);
        router.push({ name: 'AssignEntries' })
        commit("updateProductLoading", !true)
        //commit("updateProductResponse", {});

    },

    readSlabs: ({ commit, dispatch, rootState }, payload) => {
        //commit("updateProductLoading", true)
        commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadSlabsAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateSelectAcc", payload);
                    commit("updateProductResponse", responseData);
                } else {
                    // commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                //commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },

    readAccountingEntry: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadAccountingEntryAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                   // commit("updateProductView", responseData);
                  commit("updateProductViewAccountEntry", responseData);
                    commit("updateProductLoading", false);
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },


    createAccountingEntry: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callCreateAccountingEntryAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updatePage", 'create');
                    commit("updateSelectAcc", payload);
                    commit("updateProductSuccess", responseData.responseMessage);
                    commit("updateProductLoading", false);
                    //router.push({name:'AssignEntries'});
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    readProduct: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadProductAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateProductResponse", responseData);
                    console.log(responseData.responseMessage);
                    commit("updateProductLoading", false);
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },



    productsMenu: ({ commit, dispatch, rootState }, payload) => {
        console.log(JSON.stringify(payload));
        console.log("Array1>> "+JSON.stringify({products:payload.products}));
      console.log("Array2>>>> "+JSON.stringify({slabs:payload.slabs}));
      console.log("Prodcut Naeme>> "+JSON.stringify(payload.products[0].productname))
      let productName = ''

        commit("updateProductLoading", true);
        if (payload.products[0].productname == "AE") {
          commit("updateProductPayloadAE", {products:payload.products});
          commit("updateProductPayloadSlab", {slabs:payload.slabs});
          commit("updateProductdata", {
            code: payload.products[0].aeCode,
            description: payload.products[0].aeDescription,
          });
          productName = 'CH';
        }
      else if (payload.products[0].productname == "FT") {
        console.log("Slab length>>? "+state.all_product_payload.Slab.slabs.length)
        payload.products.push(
          {"bankcode":payload.products[0].bankcode,"productname":"FT",
            "userId":payload.products[0].userId,"key":"ftCode","value":state.get_allproductdata.code}
        );
        payload.products.push(
          {"bankcode":payload.products[0].bankcode,"productname":"FT",
            "userId":payload.products[0].userId,"key":"ftDescription","value":state.get_allproductdata.description}
        );
        commit("updateProductPayloadFT", payload)

          productName = 'AE';
        console.log("<<<<<<state.all_product_payload>>>>>")
        console.log(JSON.stringify(state.all_product_payload));
        console.log(state.all_product_payload);

          return ProductService.callCreateProductAPI({
            requestType: "create",
            products: state.all_product_payload.CH.products
          })
            .then(response => {
              let responseData = response.data;
              if (responseData.responseCode === "00") {
                ProductService.callCreateAccountingEntryAPI({
                  aeBankCode: state.all_product_payload.AE.products[0].aeBankCode,
                  aeChargeCode: state.all_product_payload.AE.products[0].aeChargeCode,
                  aeCode: state.all_product_payload.AE.products[0].aeCode,
                  aeDescription: state.all_product_payload.AE.products[0].aeDescription,
                  aeMaker: state.all_product_payload.AE.products[0].aeMaker,
                  source: state.all_product_payload.AE.products[0].source

                }
              )
                  .then(response1 => {
                    let responseData1 = response1.data;
                    if (responseData1.responseCode === "00") {
                      console.log(responseData1.responseMessage);
                      ProductService.callCreateProductAPI({
                        requestType:"create",
                        products:state.all_product_payload.FT.products
                      })
                        .then(response2 => {
                          let responseData2 = response2.data;
                          if (responseData2.responseCode === "00") {
                            console.log(responseData2.responseMessage);
                           //////////////////////////
                            for (let i =0;i<state.all_product_payload.Slab.slabs.length;i++) {

                              ProductService.callSubmitEntriesAPI(state.all_product_payload.Slab.slabs[i])
                                .then(response3 => {
                                  let responseData3 = response3.data;
                                  if (responseData3.responseCode === "00" ) {
                                    if(i===state.all_product_payload.Slab.slabs.length-1) {
                                      console.log(responseData3.responseMessage);
                                      commit("updateProductSuccess", responseData3.responseMessage);
                                      commit("updateProductLoading", false);
                                      commit("updateProductdata", {
                                        code: '',
                                        description: ''
                                      })
                                      //commit("updateProductResponse", responseData);
                                      router.push({name: 'Settings'});
                                      //commit("updateProductLoading", false);
                                    }
                                  } else {
                                    commit("updateProductLoading", false);
                                    commit("updateProductError", responseData3.responseMessage);
                                    console.log(responseData3.responseMessage);
                                    //swal('Error!', responseData.responseMessage, 'error').then();
                                  }

                                })
                                .catch((error) => {
                                  commit("updateProductLoading", false);
                                  commit("updateProductError", error);
                                  // throw error;
                                  // return Promise.reject(error)
                                });
                            }

                            ////////////////
                          } else {
                            commit("updateProductLoading", false);
                            commit("updateProductError", responseData2.responseMessage);
                            console.log(responseData2.responseMessage);
                            //swal('Error!', responseData.responseMessage, 'error').then();
                          }

                        })
                        .catch((error) => {
                          commit("updateProductLoading", false);
                          commit("updateProductError", error);
                          // throw error;
                          // return Promise.reject(error)
                        });
                    } else {
                      commit("updateProductLoading", false);
                      commit("updateProductError", responseData1.responseMessage);
                      console.log(responseData1.responseMessage);
                      //swal('Error!', responseData.responseMessage, 'error').then();
                    }

                  })
                  .catch((error) => {
                    commit("updateProductLoading", false);
                    commit("updateProductError", error);
                    // throw error;
                    // return Promise.reject(error)
                  });

              } else {
                commit("updateProductLoading", false);
                commit("updateProductError", responseData.responseMessage);
                console.log(responseData.responseMessage);
                //swal('Error!', responseData.responseMessage, 'error').then();
              }

            })
            .catch((error) => {
              commit("updateProductLoading", false);
              commit("updateProductError", error);
              // throw error;
              // return Promise.reject(error)
            });



          return;

      }
        else if (payload.products[0].productname == "CH") {
          payload.products.push(
            {"bankcode":payload.products[0].bankcode,"productname":"CH",
              "userId":payload.products[0].userId,"key":"chCode","value":state.get_allproductdata.code}
          );
          payload.products.push(
            {"bankcode":payload.products[0].bankcode,"productname":"CH",
              "userId":payload.products[0].userId,"key":"chDescription","value":state.get_allproductdata.description}
          );
          commit("updateProductPayloadCH", payload)
          productName = 'FT';
        }

           return ProductService.callReadProductFormAPI({
                templateBankCode: payload.products[0].bankcode,
                templateType: productName
            })
                .then(response1 => {
                    let responseData1 = response1.data;
                    console.log(responseData1.responseMessage);
                    if (responseData1.responseCode === "00") {
                        //commit("updateProductResponse", responseData);
                        commit("UpdateProductSelect","CH");
                        console.log(payload.products[0].productname)
                        console.log(responseData1.responseMessage);
                      commit("UpdateProductSelect", productName);
                        commit("updateProductLoading", false);
                        //commit("updateProductSuccess", responseData1.responseMessage);
                        //commit("updateProductResponse", responseData1);
                        //router.push('alternative');
                        commit("updateProductView", responseData1);

                    }
                    else {
                        commit("updateProductLoading", false);
                        commit("updateProductError", responseData1.responseMessage);
                        console.log(responseData1.responseMessage);
                    }

                })
                .catch((error) => {
                    commit("updateProductLoading", false);
                    commit("updateProductError", error);
                });
       // }
        /*else {
            commit("updateProductPayloadCH", payload)
            commit("updateProductLoading", false);
            router.push({name:"AssignEntries"})
        }*/

    },
  createProduct: ({ commit, dispatch, rootState }, payload) => {
    commit("updateProductLoading", true)
    //commit("updateProductResponse", {});
    commit("updateProductError", null);
    commit("updateProductSuccess", null);
    console.log(JSON.stringify(payload));
    //console.log(payload.username);callProductReadAPI
    return ProductService.callCreateProductAPI(payload)
      .then(response => {
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          console.log(responseData.responseMessage);
          //commit("updateAuthLoading", false);
          commit("updateProductSuccess", responseData.responseMessage);
          commit("updateProductResponse", responseData);
          //router.push('alternative');
          commit("updateProductLoading", false);
        } else {
          commit("updateProductLoading", false);
          commit("updateProductError", responseData.responseMessage);
          console.log(responseData.responseMessage);
          //swal('Error!', responseData.responseMessage, 'error').then();
        }

      })
      .catch((error) => {
        commit("updateProductLoading", false);
        commit("updateProductError", error);
        // throw error;
        // return Promise.reject(error)
      });
  },

    // createTransaction: ({ commit, dispatch, rootState }, payload) => {
    //     commit("updateProductLoading", true)
    //     //commit("updateProductResponse", {});
    //     commit("updateProductError", null);
    //     commit("updateProductSuccess", null);
    //     console.log(JSON.stringify(payload));
    //     //console.log(payload.username);callProductReadAPI
    //     return ProductService.callCreateProductAPI(payload)
    //         .then(response => {
    //             let responseData = response.data;
    //             if (responseData.responseCode === "00") {
    //                 console.log(responseData.responseMessage);
    //                 //commit("updateAuthLoading", false);
    //                 ProductService.callReadProductFormAPI({
    //                     templateBankCode:payload.products[0].bankcode,
    //                     templateType:"CH"})
    //                 .then(response1 => {
    //                     let responseData1 = response1.data;
    //                     console.log(responseData1.responseMessage);
    //                     if (responseData1.responseCode === "00") {
    //                         //commit("updateProductResponse", responseData);
    //                         commit("UpdateProductSelect", payload.products[0].productname);
    //                         console.log(payload.products[0].productname)
    //                         //dispatch("glLevel2Read", payload.gl, { root: false });
    //                         console.log(responseData1.responseMessage);
    //                         commit("updateProductLoading", false);
    //                         commit("updateProductSuccess", responseData1.responseMessage);
    //                         commit("updateProductResponse", responseData1);
    //                         //router.push('alternative');
    //                         commit("updateProductLoading", false);
    //                         //commit("updateAuthLoading", false);
    //                         //commit("updateProductSuccess", responseData.responseMessage);
    //                         // router.push('create-product');
    //                         //commit("updateProductResponse", responseData);
    //                         //router.push('alternative');
    //                         if (payload.products[0].productname == "FT") {
    //                             commit("updateProductdata", { code: payload.products[0].value,
    //                                                           description: payload.products[1].value,
    //                                                         })
    //                         }
    //                         commit("updateProductView", responseData1);

    //                     }
    //                     else {
    //                         commit("updateProductLoading", false);
    //                         commit("updateProductError", responseData1.responseMessage);
    //                         console.log(responseData1.responseMessage);
    //                         //swal('Error!', responseData.responseMessage, 'error').then();
    //                     }

    //                 })
    //                           .catch((error) => {
    //                     commit("updateProductLoading", false);
    //                     commit("updateProductError", error);
    //                     // throw error;
    //                     // return Promise.reject(error)
    //                 });

    //             } else {
    //                 commit("updateProductLoading", false);
    //                 commit("updateProductError", responseData.responseMessage);
    //                 console.log(responseData.responseMessage);
    //                 //swal('Error!', responseData.responseMessage, 'error').then();
    //             }

    //         })
    //         .catch((error) => {
    //             commit("updateProductLoading", false);
    //             commit("updateProductError", error);
    //             // throw error;
    //             // return Promise.reject(error)
    //         });
    // },


    getNextform: () => {

        console.log("i will get next form")

    },

    readProductView: ({ commit, dispatch, rootState }, payload) => {
        //commit("updateProductLoading1", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadProductAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    switch (payload.productType) {

                        case "LO":
                            commit("updateProductViewLoan", responseData);
                            console.log(payload.productType);
                            break;
                        case "AC":
                            commit("updateProductViewAccount", responseData);
                            console.log(payload.productType);
                            break;
                        case "IN":
                            commit("updateProductViewInterest", responseData);
                            console.log(payload.productType);
                            break;
                        case "FT":
                            commit("updateProductViewFundTransfer", responseData);
                            console.log(payload.productType);
                            break;
                        case "CH":
                            commit("updateProductViewCharge", responseData);
                            console.log(payload.productType);
                            break;
                        case "AE":
                            commit("updateProductViewAccountEntry", responseData);
                            console.log(payload.productType);
                            break;
                    }
                    commit("UpdateProductSelect", payload.productType);

                    //commit("updateProductView", responseData);
                    // commit("updateProductSuccess", responseData.responseMessage);
                    //commit("updateProductLoading1", true)
                    //commit("updateProductResponse", responseData);


                    //router.push('view-products');
                    //commit("updateProductResponse", {});
                    //commit("updateProductLoading1", false);
                } else {
                    //commit("updateProductLoading1", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                //commit("updateProductLoading1", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    productRead1: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callProductReadAPI(payload.product)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateProductResponse", responseData);
                    dispatch("glLevel2Read", payload.gl, { root: false });
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    //commit("updateProductSuccess", responseData.responseMessage);
                    commit("updateProductResponse", responseData);
                    //router.push('alternative');
                    commit("updateProductLoading", false);
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    productRead: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callProductReadAPI(payload.product)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {

                    return ProductService.callGLLevel2ReadAPI(payload.gl)
                        .then(response1 => {
                            let responseData1 = response1.data;
                            if (responseData1.responseCode === "00") {
                                commit("updateGLResponse", responseData1);
                                commit("updateProductResponse", responseData);
                                //router.push('alternative');
                                commit("updateProductLoading", false);
                            } else {
                                commit("updateProductLoading", false);
                                commit("updateProductError", responseData.responseMessage);
                                console.log(responseData.responseMessage);
                                //swal('Error!', responseData.responseMessage, 'error').then();
                            }

                        })
                        .catch((error) => {
                            commit("updateProductLoading", false);
                            commit("updateProductError", error);
                            alert(error);

                        });

                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    glLevel2Read: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductRole", {});
        commit("updateProductError", null);
        // commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(auth.getUserInfo.);
        return ProductService.callGLLevel2ReadAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    //commit("updateProductSuccess", responseData.responseMessage);
                    commit("updateGLResponse", responseData);
                    //router.push('alternative');
                    commit("updateProductLoading", false);
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                alert(error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    branchReadInfo1: ({ commit, dispatch, rootState }, payload) => {
        //commit("updateBranchLoading", true)
        commit("updateBranchRes", {});
        //commit("updateBranchError", null);
        //commit("updateBranchSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return BranchService.callBranchReadAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    commit("updateBranchRes", responseData);
                    //router.push('alternative');
                    //commit("updateBranchLoading", false);
                } else {
                    //commit("updateBranchLoading", false);
                    //commit("updateBranchError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                //commit("updateBranchLoading", false);
                //commit("updateBranchError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    productReadTemplate: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadProductTemplateAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    commit("updateProductResponse", responseData);
                    //dispatch("glLevel2Read", payload.gl, { root: false });
                    console.log(responseData.responseMessage);

                    //commit("updateAuthLoading", false);
                    //commit("updateProductSuccess", responseData.responseMessage);
                    commit("updateProductResponse", responseData);
                    //router.push('alternative');
                    commit("updateProductLoading", false);
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    readProductForm: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadProductFormAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    //commit("updateProductResponse", responseData);
                    commit("UpdateProductSelect", payload.templateType);
                    //dispatch("glLevel2Read", payload.gl, { root: false });
                    console.log(responseData.responseMessage);
                    commit("updateProductLoading", false);
                    //commit("updateAuthLoading", false);
                    //commit("updateProductSuccess", responseData.responseMessage);
                    router.push('create-product');
                    //commit("updateProductResponse", responseData);
                    //router.push('alternative');
                    commit("updateProductView", responseData);

                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },

    redirectTocharges: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        //commit("updateProductResponse", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadProductFormAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    //commit("updateProductResponse", responseData);
                    commit("UpdateProductSelect", payload.templateType);
                    console.log(payload.template)
                    //dispatch("glLevel2Read", payload.gl, { root: false });
                    console.log(responseData.responseMessage);
                    commit("updateProductLoading", false);
                    //commit("updateAuthLoading", false);
                    //commit("updateProductSuccess", responseData.responseMessage);
                    // router.push('create-product');
                    //commit("updateProductResponse", responseData);
                    //router.push('alternative');
                    if (payload.templateType == "FT") {
                        commit("updateProductdata", { code: payload.ftCode, description: payload.ftDescription })
                    }
                    commit("updateProductView", responseData);

                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },

    readProductForm1: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true)
        commit("updateProductResponseEdit", {});
        commit("updateProductError", null);
        //commit("updateProductSuccess", null);
        console.log(payload);
        //console.log(payload.username);callProductReadAPI
        return ProductService.callReadProductFormAPI(payload)
            .then(response => {
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    //commit("updateProductResponse", responseData);
                    commit("UpdateProductSelect", payload.templateType);
                    //dispatch("glLevel2Read", payload.gl, { root: false });
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    //commit("updateProductSuccess", responseData.responseMessage);
                    // router.push('create-product');
                    commit("updateProductResponseEdit", responseData);
                    commit("updateProductLoading", false);
                    //router.push('alternative');
                    //commit("updateProductView", responseData);

                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateProductLoading", false);
                commit("updateProductError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },

    updateProduct: ({ commit, dispatch, rootState }, payload) => {
        commit("updateProductLoading", true);
        return ProductService.callUpdateProductAPI({
            requestType: "update",
            products: payload.products
        })
            .then(response => {
                // commit("updateProductLoading", !true)
                let responseData = response.data;
                if (responseData.responseCode === "00") {
                    ProductService.callReadProductAPI({
                        productBankCode: payload.productBankCode,
                        productType: payload.productType
                    })
                        .then(response1 => {
                            //commit("updateCustomerLoading", !true)
                            let responseData1 = response1.data;
                            if (responseData1.responseCode === "00") {
                                //commit("updateProductResponse", responseData1);
                                console.log(responseData1.responseMessage);
                                //commit("updateAuthLoading", false);
                                commit("updateProductSuccess", responseData1.responseMessage);
                                commit("updateProductView", responseData1);
                                //router.push('alternative');
                                commit("updateProductLoading", false);

                            } else {
                                commit("updateProductLoading", false);
                                commit("updateProductError", responseData1.responseMessage);
                                console.log(responseData1.responseMessage);
                                //swal('Error!', responseData.responseMessage, 'error').then();
                            }

                        })
                        .catch(error => {
                            commit("updateProductLoading", false)
                            alert(error)
                            // throw error;
                        });
                } else {
                    commit("updateProductLoading", false);
                    commit("updateProductError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch(error => {
                commit("updateProductLoading", false)
                alert(error)
                // throw error;
            });

    },
}

export default {
    state,
    actions,
    mutations,
    getters
};
