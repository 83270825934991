import { apiClient, apiClient3 } from "./BaseService.js";

export default {
    callCustomerReadAPI(payload) {
        return apiClient.post("customerRead", payload);
    },
    callCustomerReadSingleAPI(payload) {
        return apiClient.post("customerReadSingle", payload);
    },
    callCustomerCreateAPI(payload) {
        return apiClient.post("customerCreate", payload);

    },
    callCustomerUpdateAPI(payload) {
        return apiClient.post("customerUpdate", payload);

    },
    callImageUploadAPI(payload) {
        return apiClient3.post("uploadimage2s3", payload);

    },
    callCustomerCheckerAPI(payload) {
        return apiClient.post("customerChecker", payload);

    },
    callUploadCustomersAPI(payload) {
        return apiClient.post("uploadCustomers", payload);

    },
    callAccountReadSingleAPI(payload) {
        return apiClient.post("accountReadSingle", payload);

    },
    callDocumentReadByCustomerAPI(payload) {
        return apiClient.post("documentReadByCustomer", payload);

    },

    callDocumentCreateAPI(payload) {
        return apiClient.post("documentCreate", payload);

    },
    callDocumentDeleteAPI(payload) {
        return apiClient.post("documentDelete", payload);

    },
    callDocumentAuthorizeAPI(payload) {
        return apiClient.post("documentAuthorize", payload);

    },


};