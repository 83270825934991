import { apiClient} from "./BaseService.js";


export default {
    callInternalAccountReadAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("internalAccountRead", payload);
    },
    callInternalAccountCheckerAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("internalAccountChecker", payload);
    },
    callInternalAccountCreateAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("internalAccountCreate", payload);
    },
    callRoleReadSingleAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("roleReadSingle", payload);
    },
    callInternalAccountReadSingleAPI(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("internalAccountReadSingle", payload);
    },
};