import { apiClient} from "./BaseService.js";


export default {
    callVaultCreateApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("vaultCreate", payload);
    },
    callVaultReadApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("vaultRead", payload);
    },
    callVaultCheckerApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("vaultChecker", payload);
    },

};