import { apiClient } from "./BaseService.js";


export default {

    callReadCheckerQueueApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("readCheckerQueue", payload);
    },
    callAuthorizeCheckerQueueApi(payload) {
        // console.log("Service Payload ==>>", payload)
        return apiClient.post("authorizeCheckerQueue", payload);
    },


};